import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import ArPage from './page/ArPage';
import './globals.css';
import '@hypercloud-kr/styling-kit';
import { SolutionFunnel } from '@hypercloud/webxr-components/src/api/funnel';
import { getDeviceId } from '@hypercloud/webxr-components/src/util/util';
import dayjs from 'dayjs';
import NotYetPage from './NotYetPage';

const App = () => {
  SolutionFunnel.init({
    deviceId: getDeviceId(),
    // 운영
    appKey: 'cb99d087-75a3-4d9a-88e1-311edf3be762',
    campaignId: '57',
    // 개발
    // appKey: '20c83bdc-57ee-4810-90b0-d6dbe67e28e1',
    // campaignId: '1306',
  });

  if (dayjs().isBefore(dayjs('2024-09-14'))) {
    return <NotYetPage />;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ArPage />} />
        <Route path={'*'} element={<div>404</div>} />
      </Routes>
    </div>
  );
};

export default App;
