import {XrObject} from "@hypercloud-kr/webxr-node/dist/XrObject";
import {Scene, Vector3} from "three";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import {loadGLTFModelAsync} from "../../shared/loader";
import {COLLIDER, EffectType, positionWeight, ROAD} from "../../shared";
import {RacingScene} from "../scenes/RacingScene";
import {BusObject} from "./BusObject";
import {EffectObject} from "./EffectObject";
import {LoadingManager} from "../manager/LoadingManager";

//// landmark model ////
import cheongwadae from "../../../assets/glb/Citybus_landmark/landmark_glb/cheongwadae.glb";
import DDP from "../../../assets/glb/Citybus_landmark/landmark_glb/DDP.glb";
import Gwanghwamun from "../../../assets/glb/Citybus_landmark/landmark_glb/Gwanghwamun.glb";
import Gyeongbokgung from "../../../assets/glb/Citybus_landmark/landmark_glb/Gyeongbokgung.glb";
import Heunginjimun from "../../../assets/glb/Citybus_landmark/landmark_glb/Heunginjimun.glb";
import naksan from "../../../assets/glb/Citybus_landmark/landmark_glb/naksan.glb";
import ssamziegil from "../../../assets/glb/Citybus_landmark/landmark_glb/ssamziegil.glb";
import tower from "../../../assets/glb/Citybus_landmark/landmark_glb/tower.glb";
import YiSunsin from "../../../assets/glb/Citybus_landmark/landmark_glb/Yi Sunsin.glb";
////////////////////////

//// obstacle model ////
import barricade from "../../../assets/glb/Citybus_landmark/background/barricade.glb";
import traffic_cone from "../../../assets/glb/Citybus_landmark/background/traffic cone.glb";
////////////////////////

const collisionObjects = [
    { name: 'Cheongwadae', path: cheongwadae, score: 50, effectType: EffectType.LANDMARK},
    { name: 'DDP', path: DDP, score: 50, effectType: EffectType.LANDMARK },
    { name: 'Gwanghwamun', path: Gwanghwamun, score: 50, effectType: EffectType.LANDMARK },
    { name: 'Gyeongbokgung', path: Gyeongbokgung, score: 50, effectType: EffectType.LANDMARK },
    { name: 'Heunginjimun', path: Heunginjimun, score: 50, effectType: EffectType.LANDMARK },
    { name: 'Naksan', path: naksan, score: 50, effectType: EffectType.LANDMARK },
    { name: 'Ssamziegil', path: ssamziegil, score: 50, effectType: EffectType.LANDMARK },
    { name: 'Tower', path: tower, score: 50, effectType: EffectType.LANDMARK },
    { name: 'Yi Sunsin', path: YiSunsin, score: 50, effectType: EffectType.LANDMARK },
    { name: 'barricade', path: barricade, score: -10, effectType: EffectType.BARRICADE },
    { name: 'barricade', path: barricade, score: -10, effectType: EffectType.BARRICADE },
    { name: 'barricade', path: barricade, score: -10, effectType: EffectType.BARRICADE },
    { name: 'barricade', path: barricade, score: -10, effectType: EffectType.BARRICADE },
    { name: 'trafficCone', path: traffic_cone, score: -10, effectType: EffectType.BARRICADE },
    { name: 'trafficCone', path: traffic_cone, score: -10, effectType: EffectType.BARRICADE },
    { name: 'trafficCone', path: traffic_cone, score: -10, effectType: EffectType.BARRICADE },
    { name: 'trafficCone', path: traffic_cone, score: -10, effectType: EffectType.BARRICADE },
    { name: 'barricade', path: barricade, score: -10, effectType: EffectType.BARRICADE },
    { name: 'barricade', path: barricade, score: -10, effectType: EffectType.BARRICADE },
    { name: 'barricade', path: barricade, score: -10, effectType: EffectType.BARRICADE },
    { name: 'barricade', path: barricade, score: -10, effectType: EffectType.BARRICADE },
    { name: 'trafficCone', path: traffic_cone, score: -10, effectType: EffectType.BARRICADE },
    { name: 'trafficCone', path: traffic_cone, score: -10, effectType: EffectType.BARRICADE },
    { name: 'trafficCone', path: traffic_cone, score: -10, effectType: EffectType.BARRICADE },
    { name: 'trafficCone', path: traffic_cone, score: -10, effectType: EffectType.BARRICADE },
    { name: 'barricade', path: barricade, score: -10, effectType: EffectType.BARRICADE },
    { name: 'barricade', path: barricade, score: -10, effectType: EffectType.BARRICADE },
    { name: 'barricade', path: barricade, score: -10, effectType: EffectType.BARRICADE },
    { name: 'trafficCone', path: traffic_cone, score: -10, effectType: EffectType.BARRICADE },
    { name: 'trafficCone', path: traffic_cone, score: -10, effectType: EffectType.BARRICADE },
];
collisionObjects.sort(() => Math.random() - 0.5);

export const collisionCount = collisionObjects.length;

export class CollisionObject extends XrObject {
    private effectObject: EffectObject;
    public effectType: string;
    constructor(private scene: RacingScene, private busObject: BusObject, private index: number) {
        super();
        this.repositionModel = false;

        const x = (parseInt(`${Math.random() * 3}`) - 1) * positionWeight;
        this.position = new Vector3(COLLIDER.position.x + x, COLLIDER.position.y, -30 * index + COLLIDER.position.z);

        this.effectType = collisionObjects[index].effectType;
        this.effectObject = new EffectObject(this.scene, this);
        this.appendChild(this.effectObject);
        const loading = loadGLTFModelAsync(
            collisionObjects[index].path,
            collisionObjects[index].name,
            this.position,
            new Vector3(ROAD.rotation.x, ROAD.rotation.y, ROAD.rotation.z),
            undefined,
            true
        ).then(this.onLoadModel(scene.scene));
        LoadingManager.getInstance().setState(loading);
    }

    protected onLoadModel(scene: Scene): (model: GLTF) => void {
        return (model: GLTF) => {
            super.onLoadModel(scene)(model);
            model.scene.traverse((child: any) => {
                child.castShadow = true;
                child.receiveShadow = true;
            });
        };
    }

    update() {
        super.update();
        if(!this.model?.scene) return;
        // 충돌체크
        if (this.model.scene.visible && this.busObject.checkCollision(this.model)) {
            this.model.scene.visible = false;
            this.effectObject.applyCollisionEffect();
            RacingScene.instance.score += collisionObjects[this.index].score;
            this.busObject.scoreObject.visibleScore(collisionObjects[this.index].score);
        }
    }

    render() {
        super.render();
    }
}
