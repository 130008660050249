import styled from "styled-components";
import ScoreImg from "../assets/png/Score.png";

const ScoreUI = () => {
    return <ScoreWrapper id={'score'}>
        <ScoreText id={'scoreText'} />
        <ScoreGroup>
            <ScoreNumShadow id={'scoreNumShadow'}>{ 0 }</ScoreNumShadow>
            <ScoreNum id={'scoreNum'}>0</ScoreNum>
        </ScoreGroup>
    </ScoreWrapper>
}

export default ScoreUI;

const ScoreWrapper = styled.div`
    position: fixed;
    display: none;
    
    flex-direction: column;
    
    top: 76px;
    
    gap: 2px;
`;

const ScoreText = styled.div`
    width: 94px;
    height: 24px;

    background: url('${ScoreImg}') 50% / cover no-repeat;
`;

const ScoreGroup = styled.div`
    position: relative;
    left:50%
`;

const ScoreNumShadow = styled.div`
    z-index: 1;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    
    font-feature-settings: 'case' on;
    text-shadow: 0px 5px 0px #3F0000;
    -webkit-text-stroke-color: #3E0000;
    -webkit-text-stroke-width: 1px;
    font-family: "Russo One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 48px */
`;

const ScoreNum = styled.div`
    z-index: 2;
    position: absolute;
    top: 0;
    transform: translateX(-50%);

    text-align: center;
    font-feature-settings: 'case' on;
    font-family: "Russo One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 48px */

    background: linear-gradient(180deg, #FFC566 15.63%, #F4795A 85.42%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
