import {MeshPhongMaterial, Scene, Vector3} from "three";
import {XrObject} from "@hypercloud-kr/webxr-node/dist/XrObject";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import {loadGLTFModelAsync, loadOBJModelAsync} from "../../shared/loader";
import {defaultPosition} from "../../shared";
import {LoadingManager} from "../manager/LoadingManager";
import {RacingTask} from "../RacingTask";
import {FaceContentsScene} from "../scenes/FaceContentsScene";

//// face content model ////
// @ts-ignore
import OcclusionObj from '../../../assets/glb/canonical_face_model.obj';
import Sunglasses from "../../../assets/glb/Sunglasses_2.glb";
////////////////////////////

export class FaceContentsObject extends XrObject {
    private faceOccluder: any;
    constructor(private scene: FaceContentsScene, private racingTask: RacingTask) {
        super();

        this.repositionModel = false;

        const loading = loadGLTFModelAsync(
            Sunglasses,
            'sunglasses',
            new Vector3(defaultPosition, defaultPosition, defaultPosition),
            undefined,
            undefined,
            true
        ).then(this.onLoadModel(scene.scene));

        loadOBJModelAsync(
            OcclusionObj,
            'occluder',
            new Vector3(defaultPosition, defaultPosition, defaultPosition),
            undefined,
            undefined,
            true)
            .then((model: any) => {
                    this.faceOccluder = model;
                    this.scene.scene.add(model);
                    this.createOccluder();
                }
            );
        LoadingManager.getInstance().setState(loading);
    }

    protected onLoadModel(scene: Scene): (model: GLTF) => void {
        return (model: GLTF) => {
            super.onLoadModel(scene)(model);
            model.scene.renderOrder = 3;
        };
    }

    private createOccluder = () => {
        const occluderMat = new MeshPhongMaterial();
        this.faceOccluder.traverse( function ( child ) {
            if ( child.isMesh ) {
                child.material = occluderMat;
                child.material.color.set( 0x0000ff);
                child.material.colorWrite = false;
                child.renderOrder = 2;
            }
        } );
    }

    updateContentTransform() {
        if(this.racingTask.faceTask.output === undefined) return;

        if(this.racingTask.faceTask.output.faceLandmarks.length > 0 && this.model && this.faceOccluder){
            const mat = this.racingTask.faceTask.output.facialTransformationMatrixes[0].data;
            this.faceOccluder.matrixAutoUpdate = false;
            this.faceOccluder.matrix.set(
               -mat[0], -mat[4], -mat[8],  -mat[12],
                mat[1],  mat[5],  mat[9],   mat[13],
                mat[2],  mat[6],  mat[10],  mat[14] - 3,
                mat[3],  mat[7],  mat[11],  mat[15]
            );
            this.faceOccluder.updateMatrixWorld( true );
            this.model.scene.matrixAutoUpdate = false;
            this.model.scene.matrix.set(
               -mat[0], -mat[4], -mat[8],  -mat[12],
                mat[1],  mat[5],  mat[9],   mat[13],
                mat[2],  mat[6],  mat[10],  mat[14],
                mat[3],  mat[7],  mat[11],  mat[15]
            );
            this.model.scene.updateMatrixWorld( true );
        }
    }

    update() {
        super.update();
        this.updateContentTransform();
    }

    render() {
        super.render();
    }
}
