import dayjs from 'dayjs';
import styled from '@emotion/styled';

export default function NotYetPage() {
  return (
    <Wrapper>
      <div>아직 이벤트 기간이 아닙니다.</div>
      <Description>
        {dayjs('2024-09-14').format('YYYY년 MM월 DD일')}부터 참여 가능합니다.
      </Description>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #333;
  padding: 24px;
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: #666;
  margin-top: 20px;
`;
