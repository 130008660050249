import {XrScene, XrSceneConfig} from "@hypercloud-kr/webxr-node/dist/XrScene";
import {BusObject} from "../objects/BusObject";
import {RoadObject} from "../objects/RoadObject";
import {RacingTask} from "../RacingTask";
import {collisionCount, CollisionObject} from "../objects/CollisionObject";
import {ResourceManager} from "@hypercloud-kr/graphics-components/dist/ResourceManager";
import {LoadingManager} from "../manager/LoadingManager";
import {GameStateManager} from "../manager/GameStateManager";
import {Vector3} from "three";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import {animate} from "@hypercloud-kr/webxr-node/dist/animate";
import {endPosition, GameState} from "../../shared";

type FaceRaceConfig = XrSceneConfig & {
    racingTask: RacingTask;
};

export class RacingScene extends XrScene {
    static instance: RacingScene;
    static initialized = false;
    static initializedCallbacks: (() => void)[] = [];

    static getInstance() {
        if (!this.instance) {
            throw new Error('FaceRace is not initialized');
        }
        return this.instance;
    }

    private busObject: BusObject;
    private roadObject: RoadObject;
    private collisionObjects: CollisionObject[] = [];
    private gameStateManager: GameStateManager;

    constructor(config: FaceRaceConfig) {
        super(config);
        LoadingManager.init();
        ResourceManager.init(config.renderer, config.scene);

        this.busObject = new BusObject(this, config.racingTask);
        this.appendChild(this.busObject);
        this.roadObject = new RoadObject(this);
        this.appendChild(this.roadObject);

        this.collisionObjects = Array.from({length: collisionCount},
            (_, i) => new CollisionObject(this, this.busObject, i))
            .map(obj => {
                this.appendChild(obj);
                return obj;
            });

        this.gameStateManager = new GameStateManager(this, this.busObject);
    }

    static init(config: FaceRaceConfig) {
        if (this.initialized) {
            console.error('FaceRace is already initialized');
            return;
        }
        this.instance = new RacingScene(config);
        this.initialized = true;

        // callback 실행
        RacingScene.initializedCallbacks.forEach(callback => callback());

        animate(this.instance)(0);
    }

    private _score: number = 0;
    set score(newScore: number) {
        this._score = newScore;
        document.getElementById('scoreNum').textContent = this._score.toString();
        document.getElementById('scoreNumShadow').textContent = this._score.toString();
    }

    get score() {
        return this._score;
    }

    public moveTowardsTargetPosition(targetModel: GLTF, targetPosition: Vector3) {
        const prevPosition = targetModel.scene.position.clone();
        // 매 프레임 10%씩 목표 위치로 이동
        const diffPosition = prevPosition.sub(targetPosition).multiply(new Vector3(0.1, 0.1, 1));
        targetModel.scene.position.sub(diffPosition);
    }

    update() {
        if (this.gameStateManager.gameState === GameState.END
            || this.gameStateManager.gameState === GameState.STOP) return;

        if(this.gameStateManager.gameState === GameState.PAUSE) {
            this.gameStateManager.countDown();
        }

        super.update();

        this.gameStateManager.checkGameEnd();
        if (this.gameStateManager.gameState !== GameState.IN_PROGRESS) {
            this.gameStateManager.callInProgressComponents();
        }

        // gauge bar
        document.getElementById('gaugeBarImage').style.width = Math.max(10,
            ((-endPosition - (-endPosition + this.busObject.position.z)) / -endPosition * 100)) + "%";

        // gauge bar bus icon
        document.getElementById('gaugeBarBus').style.left = Math.max(0,
            ((-endPosition - (-endPosition + this.busObject.position.z)) / -endPosition * 90)) + "%";
    }

    render() {
        if (this.gameStateManager.gameState === GameState.END) return;

        super.render();
    }
}
