import {HcSwal} from "@hypercloud/webxr-components/src/components/Modal/HCSwal";

export class LoadingManager {
    static instance: LoadingManager;
    static initialized = false;
    private state: Promise<any>[] = [];

    static getInstance() {
        if (!this.instance) {
            throw new Error('LoadingManager is not initialized');
        }
        return this.instance;
    }

    static init() {
        if (this.initialized) {
            console.error('FaceRace is already initialized');
            return;
        }
        this.instance = new LoadingManager();
        this.initialized = true;
    }

    constructor() {}

    setState(loading: Promise<any>) {
        this.state.push(loading.then(() => this.checkAllLoaded(loading)));
    }

    private checkAllLoaded(promiseLogic: Promise<any>) {
        const target = this.state.findIndex(p => p === promiseLogic);
        this.state.splice(target, 1);

        if (this.loaded && HcSwal.isVisible()) {
            HcSwal.close();
        }
    }

    get loaded() {
        return this.state.length === 0;
    }
}
