import * as THREE from "three";
import {Scene, Vector3} from "three";
import {XrObject} from "@hypercloud-kr/webxr-node/dist/XrObject";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import {loadGLTFModelAsync} from "../../shared/loader";
import {EffectType} from "../../shared";
import {RacingScene} from "../scenes/RacingScene";
import {CollisionObject} from "./CollisionObject";
import {LoadingManager} from "../manager/LoadingManager";

//// effect model ////
import Collision from "../../../assets/glb/Citybus_effects/Collision.glb";
import Collision2Only from "../../../assets/glb/Citybus_effects/Collision2Only.glb";
import ItemGetOnly from "../../../assets/glb/Citybus_effects/ItemGetOnly.glb";
////////////////////////

const effectObjects = [
    { name: 'Collision', path: Collision, effectType: EffectType.BARRICADE },
    { name: 'Collision2Only', path: Collision2Only, effectType: EffectType.BARRICADE },
    { name: 'ItemGetOnly', path: ItemGetOnly, effectType: EffectType.LANDMARK }
];

export class EffectObject extends XrObject {
    private animAction: THREE.AnimationAction;
    constructor(private scene: RacingScene, private collisionObject: CollisionObject) {
        super();
        this.repositionModel = false;
        const object = this.setEffectObject();
        this.position = new Vector3(collisionObject.position.x, collisionObject.position.y, collisionObject.position.z - 1);
        const loading = loadGLTFModelAsync(
            object.path,
            object.name,
            this.position,
            undefined,
            undefined,
            true
        ).then(this.onLoadModel(scene.scene));
        LoadingManager.getInstance().setState(loading);
    }

    protected onLoadModel(scene: Scene): (model: GLTF) => void {
        return (model: GLTF) => {
            super.onLoadModel(scene)(model);
            this.animate();
        };
    }

    private setEffectObject() {
        switch (this.collisionObject.effectType) {
            case EffectType.BARRICADE:
                return effectObjects[1];
            case EffectType.LANDMARK:
                return effectObjects[2];
            default:
                break;
        }
    }

    applyCollisionEffect() {
        if (!this.animAction) return;
        this.model.scene.visible = true;
        this.position = this.collisionObject.position;
        this.animAction.setLoop(THREE.LoopOnce, 1);
        this.animAction.timeScale = 1;
        this.animAction.play();
    }

    animate() {
        const clips = this.model.animations;
        if(clips.length === 0) return;
        for(const c in clips) {
            const clip = THREE.AnimationClip.findByName(clips, clips[c].name);
            this.animAction = this.modelMixer.clipAction(clip);
        }
    }

    update() {
        super.update();
    }

    render() {
        super.render();
    }
}
