// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}

.cherry-bomb-font {
  color: var(--common-100, #FFF);
  text-align: center;
  font-feature-settings: 'case' on;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Cherry Bomb One;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 64px */
  letter-spacing: -0.408px;
}
`, "",{"version":3,"sources":["webpack://./src/globals.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,gCAAgC;EAChC,4CAA4C;EAC5C,4BAA4B;EAC5B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;EAC5B,wBAAwB;AAC1B","sourcesContent":["body {\n  margin: 0;\n}\n\n.cherry-bomb-font {\n  color: var(--common-100, #FFF);\n  text-align: center;\n  font-feature-settings: 'case' on;\n  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n  font-family: Cherry Bomb One;\n  font-size: 64px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 100%; /* 64px */\n  letter-spacing: -0.408px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
