import {XrScene, XrSceneConfig} from "@hypercloud-kr/webxr-node/dist/XrScene";
import {FaceContentsObject} from "../objects/FaceContentsObject";
import {RacingTask} from "../RacingTask";

type FaceRaceConfig = XrSceneConfig & {
    racingTask: RacingTask;
};

export class FaceContentsScene extends XrScene {
    static instance: FaceContentsScene;
    static initialized = false;
    static initializedCallbacks: (() => void)[] = [];

    static getInstance() {
        if (!this.instance) {
            throw new Error('FaceContentsScene is not initialized');
        }
        return this.instance;
    }

    private faceContentsObject: FaceContentsObject;
    constructor(config: FaceRaceConfig) {
        super(config);

        this.faceContentsObject = new FaceContentsObject(this, config.racingTask);
        this.appendChild(this.faceContentsObject);
    }

    static init(config: FaceRaceConfig) {
        if (this.initialized) {
            console.error('FaceContentsScene is already initialized');
            return;
        }
        this.instance = new FaceContentsScene(config);
        this.initialized = true;

        // callback 실행
        FaceContentsScene.initializedCallbacks.forEach(callback => callback());
    }

    update() {
        super.update();
    }

    render() {
        super.render();
    }
}
