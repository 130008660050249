import {Vector3} from "three";
import {ResourceManager} from "@hypercloud-kr/graphics-components/dist/ResourceManager";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";

export const loadGLTFModelAsync = async (model: any, name?: string, position?: Vector3,
                                         rotation?: Vector3, scale?: Vector3, visibility?: boolean) => {
    const result = await ResourceManager.instance.loadGLTF(model);
    result.scene.name = name;
    if( position !== undefined ) result.scene.position.set(position.x, position.y, position.z);
    if( rotation !== undefined) result.scene.rotation.set(rotation.x, rotation.y, rotation.z);
    if( scale !== undefined) result.scene.scale.set(scale.x, scale.y, scale.z);
    if( visibility !== undefined ) result.scene.visible = visibility;
    return result;
}

export const loadOBJModelAsync = (model: any, name?: string, position?: Vector3,
                                  rotation?: Vector3, scale?: Vector3, visibility?: boolean) => {
    const loader = new OBJLoader();

    return new Promise((resolve, reject) => {
        loader.load(
            model,
            (object) => {
                object.name = name;
                if( position !== undefined ) object.position.set(position.x, position.y, position.z);
                if( rotation !== undefined) object.rotation.set(rotation.x, rotation.y, rotation.z);
                if( scale !== undefined) object.scale.set(scale.x, scale.y, scale.z);
                if( visibility !== undefined ) object.visible = visibility;
                resolve(object);
            },
            (xhr) => {
                console.log((xhr.loaded / xhr.total * 100) + '% loaded');
            },
            (error) => {
                reject(new Error('An error occurred while loading the OBJ model'));
            }
        );
    });
}
