
export const raceSpeed = 12;

export const boosterSpeed = 18;

export const boosterScore = 20;

export const endPosition = -1000;

export const defaultPosition = 9999;

export const positionWeight = 2.5;

export enum GameState {
    START,
    PAUSE,
    IN_PROGRESS,
    SUCCESS,
    STOP,
    END
}

export enum EffectType {
    BARRICADE = 'BARRICADE',
    LANDMARK = 'LANDMARK'
}

type PlaneVector3 = {
    x: number;
    y: number;
    z: number;
}

type ObjectInitialSetting = {
    position: PlaneVector3;
    rotation?: PlaneVector3;
    scale?: PlaneVector3;
}

export const BUS: ObjectInitialSetting = {
    position: {
        x: 0,
        y: 0,
        z: -18
    },
    rotation: {
        x: 0,
        y: 0,
        z: 0
    },
    scale: {
        x: -0.7,
        y: 0.7,
        z: -0.7
    }
}

export const ROAD: ObjectInitialSetting = {
    position: {
        x: 0,
        y: -0.4,
        z: -100
    },
    rotation: {
        x: 0,
        y: Math.PI / 2,
        z: 0
    }
}

export const COLLIDER: ObjectInitialSetting = {
    position: {
        x: 0,
        y: 0,
        z: -100
    }
}
