import {XrObject} from "@hypercloud-kr/webxr-node/dist/XrObject";
import {RacingScene} from "../scenes/RacingScene";
import {BUS} from "../../shared";
import {CSS2DObject, CSS2DRenderer} from "three/examples/jsm/renderers/CSS2DRenderer";
import {BusObject} from "./BusObject";

import Score10 from "../../../assets/png/img_score_10.png";
import Score20 from "../../../assets/png/img_score_20.png";
import Score50 from "../../../assets/png/img_score_50.png";

export class ScoreObject extends XrObject {
    private labelRenderer: CSS2DRenderer;
    private readonly scoreLabel: CSS2DObject;
    private readonly scoreDiv: HTMLDivElement;
    private timeoutId: number;
    private boosterTimeoutId: number;
    private useBooster: boolean = false;

    constructor(private scene: RacingScene, private busObject: BusObject) {
        super();
        this.labelRenderer = new CSS2DRenderer();
        this.labelRenderer.setSize(window.innerWidth, window.innerHeight);
        this.labelRenderer.domElement.style.position = 'absolute';
        this.labelRenderer.domElement.style.display = 'none';
        this.labelRenderer.domElement.style.top = '0px';
        document.getElementById('root').appendChild(this.labelRenderer.domElement);

        this.scoreDiv = document.createElement('div');
        this.scoreDiv.style.width = '91px';
        this.scoreDiv.style.height = '43px';

        this.scoreLabel = new CSS2DObject(this.scoreDiv);
        this.scoreLabel.position.set(BUS.position.x, BUS.position.y + 2, BUS.position.z);
        this.scene.scene.add(this.scoreLabel);
    }

    public visibleScore(score: number) {
        //// 장애물 또는 랜드마크 충돌 시 점수 표기 ////
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.labelRenderer.domElement.style.display = 'flex';
        const backgroundImg = score > 0 ? Score50 : Score10;
        this.scoreDiv.style.background = `url(${backgroundImg}) 50% / cover no-repeat`
        this.scoreLabel.position.x = this.busObject.position.x;
        this.timeoutId = setTimeout(() => {
            this.labelRenderer.domElement.style.display = 'none';
        }, 500) as unknown as number;
    }

    update() {

        //// 부스터 사용 시 점수 표기 ////////////////////////////////
        if (this.busObject.booster && !this.useBooster ) {
            this.useBooster = true;
            if (this.boosterTimeoutId) {
                clearTimeout(this.boosterTimeoutId);
            }
            this.labelRenderer.domElement.style.display = 'flex';
            this.scoreDiv.style.background = `url(${Score20}) 50% / cover no-repeat`;
            this.scoreLabel.position.x = this.busObject.position.x;
            this.boosterTimeoutId = setTimeout(() => {
                this.labelRenderer.domElement.style.display = 'none';
            }, 500) as unknown as number;
        }

        if (!this.busObject.booster && this.useBooster ) {
            this.useBooster = false;
        }
        ////////////////////////////////////////////////////////

        this.scoreLabel.position.z = this.busObject.position.z;
    }

    render() {
        this.labelRenderer.render(this.scene.scene, this.scene.camera);
    }
}
