import WebXRHuman from '@hypercloud-kr/webxr-human/dist/web_xr_human';

export class RacingTask {
    faceTask: WebXRHuman;
    public mouthOpen: boolean = false;
    public facePosition: "left" | "center" | "right" = "center";

    constructor() {
        // mode : FACE or SEGMENTATION
        WebXRHuman.init({
            mode: "FACE",
            numFaces: 1
        });

        // 필수!! WebXRHuman.initialize() 후에 WebXRHuman.getInstance() 호출
        this.faceTask = WebXRHuman.getInstance();
    }

    async animate(video: HTMLVideoElement) {
        let startTimeMs = performance.now();

        // HTMLVideoElement를 Web Worker에서 사용할 수 없어 image bitmap으로 변경해서 넘겨줌
        const bitmap = await createImageBitmap(video);
        return this.faceTask.runModel(bitmap, startTimeMs);
    }

    updateTransform(results) {
        if (results === undefined) return;
        if (results.faceLandmarks.length > 0) {
            const radian = Math.atan2(results.faceLandmarks[0][10].y - results.faceLandmarks[0][152].y,
                results.faceLandmarks[0][10].x - results.faceLandmarks[0][152].x);
            const degree = -(radian * (180 / Math.PI));

            // 오브젝트 좌우 이동
            if (degree > 100) {
                this.facePosition = "right";
            } else if (degree < 80) {
                this.facePosition = "left";
            } else {
                this.facePosition = "center";
            }

            // 부스터 효과
            if (results.faceBlendshapes[0].categories[25].score > 0.4) {
                this.mouthOpen = true;
            } else if (results.faceBlendshapes[0].categories[25].score < 0.4 && this.mouthOpen) {
                this.mouthOpen = false;
            }
        }
    }
}
