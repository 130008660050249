import styled from '@emotion/styled';
import Intro from '../assets/png/splash.png';
import Play from '../assets/png/button_co_on_01.png';
import {
  FunnelAttributionType,
  SolutionFunnel,
} from '@hypercloud/webxr-components/src/api/funnel';

export default function IntroPage() {
  return (
    <Wrapper id={'introPage'}>
      <IntroImage src={`${Intro}`} />
      <ButtonWrapper>
        <PlayButton
          src={Play}
          onClick={() => {
            document.getElementById('introPage').style.display = 'none';
            document.getElementById('manualPage').style.display = 'flex';
            SolutionFunnel.stack(FunnelAttributionType.GUIDE_PAGE_IN);
          }}
        />
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 101;
`;

const IntroImage = styled.img`
  width: 100svw;
  height: 100svh;

  margin: auto;
`;

const ButtonWrapper = styled.div`
  position: fixed;

  width: calc(100% - 128px);
  height: 62px;

  bottom: 40px;
`;

const PlayButton = styled.img`
  width: 100%;
  height: 62px;
`;
