import { endPosition, GameState } from '../../shared';
import { RacingScene } from '../scenes/RacingScene';
import { BusObject } from '../objects/BusObject';

import Count1 from '../../../assets/png/img_count_1.png';
import Count2 from '../../../assets/png/img_count_2.png';
import Count3 from '../../../assets/png/img_count_3.png';
import { SolutionFunnel } from '@hypercloud/webxr-components/src/api/funnel';

const countImages = {
  1: Count1,
  2: Count2,
  3: Count3,
};

export class GameStateManager {
  public gameState: GameState = GameState.STOP;
  private countDownText: HTMLElement;
  private startText: HTMLElement;
  private successText: HTMLElement;
  private scoreText: HTMLElement;
  private count: number = 3;

  constructor(
    private scene: RacingScene,
    private busObject: BusObject,
  ) {
    this.initializeState();
    this.initializeUIElements();
  }

  private initializeUIElements() {
    this.countDownText = document.getElementById('countDown');
    this.startText = document.getElementById('start');
    this.successText = document.getElementById('success');
    this.scoreText = document.getElementById('score');
  }

  private initializeState() {
    this.gameState = GameState.PAUSE;
  }

  private callStartComponents() {
    this.gameState = GameState.IN_PROGRESS;
    this.startText.style.display = 'flex';
    setTimeout(() => {
      this.startText.style.display = 'none';
      this.scoreText.style.display = 'flex';
    }, 1000);
  }

  callInProgressComponents() {
    switch (this.gameState) {
      case GameState.START:
        this.callStartComponents();
        this.gameState = GameState.IN_PROGRESS;
        break;
      case GameState.SUCCESS:
        this.callSuccessComponents();
        this.gameState = GameState.END;
        break;
    }
  }

  private callSuccessComponents() {
    document.getElementById('confetti').style.display = 'flex';
    setTimeout(() => {
      this.scoreText.style.display = 'none';
      this.successText.style.display = 'flex';
      setTimeout(() => {
        this.successText.style.display = 'none';
        document.getElementById('confetti').style.display = 'none';
        document.getElementById('endingPage').style.display = 'flex';
        document.getElementById('finalScoreNumShadow').textContent = String(
          this.scene.score,
        );
        document.getElementById('finalScore').textContent = String(
          this.scene.score,
        );
        SolutionFunnel.finishInfo(this.scene.score);
      }, 1000);
    }, 1000);
  }

  countDown() {
    this.gameState = GameState.STOP;
    const countId = setInterval(() => {
      this.countDownText.setAttribute('count', String(this.count));
      this.countDownText.style.background = `url(${countImages[this.count] || 'none'}) 50% / cover no-repeat`;
      this.count--;
      if (this.count < 0) {
        this.countDownText.style.display = 'none';
        this.startText.style.display = 'flex';
        this.gameState = GameState.START;
        clearInterval(countId);
      }
    }, 1000);
  }

  checkGameEnd() {
    if (-this.busObject.position.z >= -endPosition) {
      this.gameState = GameState.SUCCESS;
    }
  }
}
