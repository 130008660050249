import {XrObject} from "@hypercloud-kr/webxr-node/dist/XrObject";
import {RacingScene} from "../scenes/RacingScene";
import {Scene, Vector3} from "three";
import {loadGLTFModelAsync} from "../../shared/loader";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import RoadModel from "../../../assets/glb/Citybus_Road.glb";

import { ROAD } from "../../shared";
import {LoadingManager} from "../manager/LoadingManager";
import {EndLineObject} from "./EndLineObject";

declare let THREE: any;

export class RoadObject extends XrObject {
  private endLineObject: EndLineObject;
  constructor(private scene: RacingScene) {
    super();

    this.position = new Vector3(ROAD.position.x, ROAD.position.y, ROAD.position.z);
    const loading = loadGLTFModelAsync(
        RoadModel,
        'road',
        this.position,
        new Vector3(ROAD.rotation.x, ROAD.rotation.y, ROAD.rotation.z),
        new Vector3(1, 1, 1),
        true
    ).then(this.onLoadModel(scene.scene));
    this.endLineObject = new EndLineObject(this.scene);
    this.appendChild(this.endLineObject);
    LoadingManager.getInstance().setState(loading);
  }

  protected onLoadModel(scene: Scene): (model: GLTF) => void {
    return (model: GLTF) => {
      super.onLoadModel(scene)(model);
      model.scene.traverse((child: any) => {
        child.castShadow = true;
        child.receiveShadow = true;
      });
      this.animate();
    };
  }

  animate() {
    const clips = this.model.animations;
    if(clips.length === 0) return;
    for(const c in clips) {
      const clip = THREE.AnimationClip.findByName(clips, clips[c].name);
      const action = this.modelMixer.clipAction(clip);
      action.play();
    }
  }

  update() {
    super.update();
  }

  render() {
    super.render();
  }
}
