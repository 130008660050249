import React, {useCallback, useEffect, useRef} from "react";
import {ARViewRenderer} from "./ARViewRenderer";
import { RacingTask } from "./details/RacingTask";
import RacingUI from "../components/RacingUI";
import {createRoot} from "react-dom/client";
import styled from "@emotion/styled";
import {Guide, palette} from "@hypercloud-kr/styling-kit";
import IcCamera from "../assets/svg/IcCamera";
import {HcSwalRoot} from "@hypercloud/webxr-components/src/components/Modal/HCSwal";
import {LoadingPage} from "../page/LoadingPage";
import EndingPage from "../page/EndingPage";

const ARView = () => {

    HcSwalRoot({
        component: <LoadingPage />,
        backdrop: true,
        animation: false
    });

    let racingTask: RacingTask;

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasRef1 = useRef<HTMLCanvasElement>(null);
    const cameraFeedingRef = useRef<HTMLVideoElement>(null);

    const init = useCallback(() => {
        const video = cameraFeedingRef.current;
        const canvas = document.getElementById('camView') as HTMLCanvasElement;
        const context = canvas.getContext('2d');

        let videoWidth, videoHeight;
        if (video) {
            navigator.mediaDevices.getUserMedia({
                video: {
                    width: {exact: 360},
                    height: {exact: 270},
                    facingMode: "user"
                }
            })
                .then(stream => {
                    // 비디오 요소의 소스에 스트림을 연결하여 웹캠 영상을 표시합니다.
                    video.srcObject = stream;
                    video.onloadedmetadata = async () => {

                        await video.play();

                        videoWidth = video.videoWidth;
                        videoHeight = video.videoHeight;

                        // Must set below two lines, otherwise video element doesn't show.
                        video.width = videoWidth;
                        video.height = videoHeight;

                        onWindowResize();
                        window.addEventListener('resize', onWindowResize);

                        drawVideoFrame();

                        const camCanvas = canvasRef1.current;
                        camCanvas.width = canvas.width;
                        camCanvas.height = canvas.height;

                        const glCanvas = canvasRef.current;
                        glCanvas.width = window.innerWidth;
                        glCanvas.height = window.innerHeight;
                        await ARViewRenderer({video, glCanvas, camCanvas, racingTask});
                    };
                })
                .catch(error => {
                    console.error('카메라를 사용할 수 없습니다:', error);
                    const container = document.querySelector('#ArPage');
                    if (container) {
                        const wrapper = document.createElement('div');
                        // @ts-ignore
                        container.appendChild(wrapper);
                        setTimeout(() => {
                            const root = createRoot(wrapper);
                            root.render(<FullDiv>
                                <Guide icon={<IcCamera/>}
                                       title={'No access to Camera'}
                                       message={'We don’t have access to your Camera. \nPlease allow access to play contents.'} />
                            </FullDiv>);
                        }, 100);
                    }
                });
        }

        function onWindowResize() {
            const videoRatio = videoWidth / videoHeight;
            const windowRatio = window.innerWidth / window.innerHeight;

            if (windowRatio > videoRatio) {
                // 창의 비율이 비디오의 비율보다 클 경우
                canvas.height = window.innerHeight;
                canvas.width = canvas.height * videoRatio;
            } else {
                // 창의 비율이 비디오의 비율보다 작을 경우
                canvas.width = window.innerWidth;
                canvas.height = canvas.width / videoRatio;
            }
        }

        function drawVideoFrame() {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            requestAnimationFrame(drawVideoFrame);
        }
    }, [])

    useEffect(() => {
        // 뒤로가기 탐지
        window.onpageshow = (event) => {
            if (event.persisted) {
                window.location.reload();
            }
        }

        if (!racingTask) {
            racingTask = new RacingTask();
        }
        init();
    }, []);

    return (
        <div id="ARPortalContainer">
            <RacingUI />
            <CameraFeed ref={cameraFeedingRef} id={"camera"} muted={true} autoPlay={true} playsInline/>
            <CameraView id={"camView"}/>
            <ContentsView ref={canvasRef1} id={"contentsView"}/>
            <ImageView ref={canvasRef} id={"arView"}/>
            <EndingPage />
        </div>
    )
}

export default ARView;

const CameraFeed = styled.video`
    display: none;
`;

const CameraView = styled.canvas`
    display: flex;
    position: absolute;
    transform: scaleX(-1);
`;

const ImageView = styled.canvas`
    width: 100vw;
    height: 100svh;
    bottom: 0;
    margin: auto;
    position: fixed;
`;

const ContentsView = styled.canvas`
    margin: auto;
    position: fixed;
`;

const FullDiv = styled.div`
    position: fixed;
    width: 100vw;
    height: 100svh;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${palette.$var.coolNeutral[5]};
    white-space: pre-wrap;
    z-index: 3;
`;
