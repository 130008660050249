import styled from '@emotion/styled';
import manual from '../assets/png/ManualBackground.png';
import howtoplay from '../assets/png/HowToPlay.png';
import num1 from '../assets/png/num1.png';
import num2 from '../assets/png/num2.png';
import num3 from '../assets/png/num3.png';
import num4 from '../assets/png/num4.png';
import Ok from '../assets/png/button_co_on_02.png';
import { useEffect } from 'react';
import {
  FunnelAttributionType,
  SolutionFunnel,
} from '@hypercloud/webxr-components/src/api/funnel';

interface ManualProps {
  setSelection: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ManualPage({ setSelection }: ManualProps) {
  return (
    <Container id={'manualPage'}>
      <Wrapper>
        <ManualTitle />
        <Frame>
          <SubFrame>
            <SubFrameImg src={`${num1}`} />
            <SubFrameText>{'Drive by moving your face'}</SubFrameText>
          </SubFrame>
          <SubFrame>
            <SubFrameImg src={`${num2}`} />
            <SubFrameText>{'Boost by opening your mouth'}</SubFrameText>
          </SubFrame>
          <SubFrame>
            <SubFrameImg src={`${num3}`} />
            <SubFrameText>{'Collect landmarks, avoid obstacles'}</SubFrameText>
          </SubFrame>
          <SubFrame>
            <SubFrameImg src={`${num4}`} />
            <SubFrameText>{'Higher score, better chance to win'}</SubFrameText>
          </SubFrame>
        </Frame>
      </Wrapper>
      <ButtonWrapper>
        <OkButton
          src={`${Ok}`}
          onClick={() => {
            setSelection(true);

            SolutionFunnel.stack(FunnelAttributionType.EVENT_CLICK, {
              clickInfo: 'StartGame',
            });
            document.getElementById('manualPage').style.display = 'none';
          }}
        ></OkButton>
      </ButtonWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100svw;
  height: 100svh;

  position: fixed;
  display: none;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: url('${manual}') lightgray 50% / cover no-repeat;

  z-index: 100;

  white-space: pre-wrap;
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;

  width: calc(100% - 40px);

  flex-direction: column;
  align-items: center;

  top: 62px;

  gap: 36px;
`;

const ManualTitle = styled.div`
  width: 281px;
  height: 31px;

  background: url('${howtoplay}') 50% / cover no-repeat;
`;

const Frame = styled.div`
  display: flex;

  width: 100%;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 24px;

  gap: 8px;
`;

const SubFrame = styled.div`
  display: flex;

  align-items: center;
  align-self: stretch;

  border-radius: 16px;

  background: var(--common-100, #fff);
  box-shadow: 0px 2px 0px 0px #000;

  padding: 8px;
`;

const SubFrameImg = styled.img`
  width: 73px;
  height: 73px;
`;

const SubFrameText = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 160% */
  letter-spacing: -0.2px;
`;

const ButtonWrapper = styled.div`
  position: fixed;

  width: calc(100% - 128px);
  height: 62px;

  bottom: 40px;
`;

const OkButton = styled.img`
  width: 100%;
  height: 62px;
`;
