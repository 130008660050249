import * as THREE from "three";
import {XrObject} from "@hypercloud-kr/webxr-node/dist/XrObject";
import {RacingScene} from "../scenes/RacingScene";
import {Scene, Vector3} from "three";
import {loadGLTFModelAsync} from "../../shared/loader";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import BusModel from "../../../assets/glb/CityBus_Stand.glb";
import { RacingTask } from "../RacingTask";
import {boosterSpeed, BUS, endPosition, positionWeight, raceSpeed} from "../../shared";
import {BoostEffectObject} from "./BoostEffectObject";
import {deltaTime} from "@hypercloud-kr/webxr-node/dist/animate";
import {LoadingManager} from "../manager/LoadingManager";
import {ScoreObject} from "./ScoreObject";

export class BusObject extends XrObject {
  public booster: boolean = false;
  private boostObject: BoostEffectObject;
  public scoreObject: ScoreObject;

  constructor(private scene: RacingScene, private racingTask: RacingTask) {
    super();

    this.repositionModel = false;

    this.boostObject = new BoostEffectObject(this.scene, this);
    this.appendChild(this.boostObject);

    this.scoreObject = new ScoreObject(this.scene, this);
    this.appendChild(this.scoreObject);

    this.position = new Vector3(BUS.position.x, BUS.position.y, BUS.position.z);
    const loading = loadGLTFModelAsync(
        BusModel,
        'bus',
        this.position,
        undefined,
        new Vector3(BUS.scale.x, BUS.scale.y, BUS.scale.z),
        true
    ).then(this.onLoadModel(scene.scene));
    LoadingManager.getInstance().setState(loading);
  }

  protected onLoadModel(scene: Scene): (model: GLTF) => void {
    return (model: GLTF) => {
      super.onLoadModel(scene)(model);
      this.animate();
    };
  }

  animate() {
    const clips = this.model.animations;
    if(clips.length === 0) return;
    for(const c in clips) {
      const clip = THREE.AnimationClip.findByName(clips, clips[c].name);
      const action = this.modelMixer.clipAction(clip);
      action.play();
    }
  }

  public checkCollision(collisionMesh: GLTF) {
    if(!this.model || !this.model.scene || !collisionMesh || !collisionMesh.scene) {
      return false;
    }
    const busBox = new THREE.Box3().setFromObject(this.model.scene);
    const collisionBox = new THREE.Box3().setFromObject(collisionMesh.scene);
    return busBox.intersectsBox(collisionBox);
  }

  update() {
    if( this.position.z < endPosition) return;

    super.update();

    // facePosition 기반으로 위치 이동
    switch(this.racingTask.facePosition) {
      case "left":
        this.position.x = -positionWeight;
        break;
      case "center":
        this.position.x = 0;
        break;
      case "right":
        this.position.x = positionWeight;
        break;
    }

    // mouthOpen 기반으로 속도 조절
    this.booster = this.racingTask.mouthOpen;
    let speed = raceSpeed * deltaTime;
    if (this.booster) {
      speed += boosterSpeed * deltaTime;
    }
    this.position.z -= speed;
    this.scene.camera.position.z -= speed;
  }

  render() {
    super.render();
    if(!this.model) return;
    this.scene.moveTowardsTargetPosition(this.model, this.position);
  }
}
