import * as React from 'react';
import { useEffect, useState } from 'react';
import IntroPage from './IntroPage';
import ARView from '../renderer/ARView';
import ManualPage from './ManualPage';
import {
  FunnelAttributionType,
  SolutionFunnel,
} from '@hypercloud/webxr-components/src/api/funnel';

const ArPage = () => {
  const [isSelect, setSelection] = useState(false);

  useEffect(() => {
    SolutionFunnel.stack(FunnelAttributionType.SCENE_START);
  }, []);

  return (
    <div id="ArPage">
      <IntroPage />
      {!isSelect && <ManualPage setSelection={setSelection} />}
      {isSelect && <ARView />}
    </div>
  );
};
export default ArPage;
