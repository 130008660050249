import * as THREE from "three";
import {Scene, Vector3} from "three";
import {XrObject} from "@hypercloud-kr/webxr-node/dist/XrObject";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import {loadGLTFModelAsync} from "../../shared/loader";
import {boosterScore, BUS} from "../../shared";
import {RacingScene} from "../scenes/RacingScene";
import {BusObject} from "./BusObject";
import {LoadingManager} from "../manager/LoadingManager";

//// effect model ////
import BoostEffect from "../../../assets/glb/Citybus_effects/BoostOnly.glb";
////////////////////////

export class BoostEffectObject extends XrObject {
    private animAction: THREE.AnimationAction;
    constructor(private scene: RacingScene, private busObject: BusObject) {
        super();

        this.repositionModel = false;

        this.position = new Vector3(BUS.position.x, BUS.position.y, BUS.position.z);
        const loading = loadGLTFModelAsync(
            BoostEffect,
            'boostEffect',
            this.position,
            new Vector3(BUS.rotation.x, BUS.rotation.y, BUS.rotation.z),
            new Vector3(-BUS.scale.x, BUS.scale.y, -BUS.scale.z),
            false
        ).then(this.onLoadModel(scene.scene));
        LoadingManager.getInstance().setState(loading);
    }

    protected onLoadModel(scene: Scene): (model: GLTF) => void {
        return (model: GLTF) => {
            super.onLoadModel(scene)(model);
            this.animate();
        };
    }

    animate() {
        const clips = this.model.animations;
        if(clips.length === 0) return;
        for(const c in clips) {
            const clip = THREE.AnimationClip.findByName(clips, clips[c].name);
            this.animAction = this.modelMixer.clipAction(clip);
        }
    }

    private _useBooster: boolean = false;
    set useBooster(newVal: boolean) {
        if (newVal === this._useBooster) return;
        if (newVal) {
            RacingScene.instance.score += boosterScore;
        }
        this._useBooster = newVal;
    }
    get useBooster() { return this._useBooster; }

    update() {
        super.update();

        if (!this.animAction) return;
        if( this.busObject.booster ) {
            this.model.scene.visible = true;
            this.position = this.busObject.position;
            this.animAction.play();
        } else {
            this.model.scene.visible = false;
            this.position = this.busObject.position;
            this.animAction.stop();
        }

        this.useBooster = this.busObject.booster;
    }

    render() {
        super.render();
        if(!this.model) return;
        this.scene.moveTowardsTargetPosition(this.model, this.position);
    }
}
