import styled, {keyframes} from "styled-components";
import loadGrad from "../assets/png/load-grad.png";

export const LoadingPage = () => {

    const swalDom = document.getElementById("swal2-html-container");
    swalDom.style.padding = '0px';
    return <LoadingWrapper id={'loadingWrapper'}>
        <LoadingImage><img src={loadGrad} alt="loadGrad" /></LoadingImage>
        <LoadingMessage id={'loading'}>{'Loading…'}</LoadingMessage>
    </LoadingWrapper>
}

const LoadingWrapper = styled.div`
    display: flex;

    width: 100vw;
    height: 100svh;

    background: #101118;

    overflow: hidden;

    z-index: 99;
`;

const LoadingMessage = styled.div`
  position: fixed;
  display: flex;

  top: 70px;
  font: var(--headline02);
  font-size: 17px;
  color: white;

  width: 100vw;
  height: 100svh;

  text-align: center;
  justify-content: center;
  align-items: center;
`;

const PortalGuideAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
`;

const LoadingImage = styled.div `
  position: fixed;
  display: flex;
  
  top: 50%;
  left: 50%;
  height: 72px;
  width: 72px;
  transform: translate(-50%, -50%);

  img {
    height: 4.5em;
    animation: ${PortalGuideAnimation} 2s infinite steps(64);
  }
`;
