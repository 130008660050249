import * as THREE from 'three';
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment";
import { RacingScene } from "./details/scenes/RacingScene";
import { RacingTask } from "./details/RacingTask";
import { isMobile } from "react-device-detect";
import {FaceContentsScene} from "./details/scenes/FaceContentsScene";

interface ARViewRendererProps {
    video: HTMLVideoElement;
    glCanvas: HTMLCanvasElement;
    camCanvas: HTMLCanvasElement;
    racingTask: RacingTask;
}

export const ARViewRenderer = async ({video, glCanvas, camCanvas, racingTask}: ARViewRendererProps) => {

    let raceScene: THREE.Scene, raceCamera: THREE.PerspectiveCamera, raceRenderer: THREE.WebGLRenderer;
    let faceScene: THREE.Scene, faceCamera: THREE.PerspectiveCamera, faceRenderer: THREE.WebGLRenderer;

    // 카메라를 생성합니다.
    const fov = 50;
    const CAM_ZNEAR = 0.01;
    const CAM_ZFAR = 100;

    const setWebGLRenderer = async (canvas: HTMLCanvasElement) => {
        const renderer = new THREE.WebGLRenderer({canvas: canvas, antialias: true, alpha: true});
        renderer.setClearColor(0x000000, 0);
        renderer.outputColorSpace = THREE.SRGBColorSpace;
        renderer.toneMapping = THREE.LinearToneMapping;
        (renderer as any).physicallyCorrectLights = true;
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        renderer.toneMappingExposure = 1;

        return renderer;
    }

    const setEnvMap = (scene: THREE.Scene, renderer: THREE.WebGLRenderer) => {
        const pmremGenerator = new THREE.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();
        scene.environment = pmremGenerator.fromScene(new RoomEnvironment()).texture;
    }

    const setLight = (scene: THREE.Scene) => {
        const directionalLight = new THREE.DirectionalLight(0xffffff, 5.5);
        directionalLight.position.set(-11, 11.234, 6);
        directionalLight.castShadow = true;
        directionalLight.frustumCulled = true;
        directionalLight.shadow.mapSize.width = 2048;
        directionalLight.shadow.mapSize.height = 2048;
        const cameraFrustum = 1000;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = cameraFrustum;

        directionalLight.shadow.camera.left = -cameraFrustum;
        directionalLight.shadow.camera.right = cameraFrustum;
        directionalLight.shadow.camera.top = cameraFrustum;
        directionalLight.shadow.camera.bottom = -cameraFrustum;
        scene.add(directionalLight);
    }

    const createRaceScene = async () => {
        raceScene = new THREE.Scene();

        let drawWidth = window.innerWidth;
        let drawHeight = window.innerHeight;
        if (isMobile) {
            drawWidth = window.innerHeight;
            drawHeight = window.innerWidth;
        }

        raceCamera = new THREE.PerspectiveCamera(fov, drawHeight / drawWidth, CAM_ZNEAR, CAM_ZFAR);
        raceCamera.position.set(0, 5, 0);
        raceCamera.rotation.set(-0.0523599, 0, 0);

        raceRenderer = await setWebGLRenderer(glCanvas);

        setLight(raceScene);
        setEnvMap(raceScene, raceRenderer);
    }

    const createFaceScene = async () => {
        faceScene = new THREE.Scene();

        faceCamera = new THREE.PerspectiveCamera(63, camCanvas.width / camCanvas.height, CAM_ZNEAR, CAM_ZFAR);
        faceCamera.position.set(0, 0, 0);
        faceCamera.rotation.set(0, 0, 0);

        faceRenderer = await setWebGLRenderer(camCanvas);

        setLight(faceScene);
        setEnvMap(faceScene, faceRenderer);
    }

    const createScene = async () => {
        await createRaceScene();
        await createFaceScene();

        initContents();
    }

    const initContents = () => {
        RacingScene.init({
            canvas: glCanvas,
            scene: raceScene,
            camera: raceCamera,
            renderer: raceRenderer,
            racingTask
        })

        FaceContentsScene.init({
            canvas: glCanvas,
            scene: faceScene,
            camera: faceCamera,
            renderer: faceRenderer,
            racingTask
        });
    }

    const animate = async () => {

        if (video && racingTask) {

            racingTask.animate(video).then((res) => {
                racingTask.updateTransform(res);
            });

            RacingScene.getInstance().update();
            RacingScene.getInstance().render();

            FaceContentsScene.getInstance().update();
            FaceContentsScene.getInstance().render();

        }
        requestAnimationFrame(animate);
    }

    createScene().then(() => {
        animate();
    });
}
