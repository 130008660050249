import styled from '@emotion/styled';
import BusGif from '../assets/Bus06.gif';
import ScoreImg from '../assets/png/score_num.png';
import PlayAgain from '../assets/png/button_co_on_03.png';
import GetCoupon from '../assets/png/button_co_on_04.png';
import {
  FunnelAttributionType,
  OnBoardingEventType,
  SolutionFunnel,
} from '@hypercloud/webxr-components/src/api/funnel';

const EndingPage = () => {
  return (
    <Container id={'endingPage'}>
      <BusWrapper src={`${BusGif}`} alt="BusGif" />
      <ScoreWrapper id={'score'}>
        <ScoreText />
        <ScoreGroup>
          <ScoreNumShadow id={'finalScoreNumShadow'}>0</ScoreNumShadow>
          <ScoreNum id={'finalScore'}>0</ScoreNum>
        </ScoreGroup>
      </ScoreWrapper>
      <ButtonWrapper>
        <PlayButton
          src={`${PlayAgain}`}
          onClick={() => {
            SolutionFunnel.stack(FunnelAttributionType.EVENT_CLICK, {
              clickInfo: 'PlayAgain',
            });
            setTimeout(() => {
              // Funnel 남기는 시간 확보
              window.location.href = '/';
            }, 300);
          }}
        ></PlayButton>
        {/*<CouponButton*/}
        {/*    src={`${GetCoupon}`}*/}
        {/*    onClick={() => {*/}
        {/*        window.location.href = '/';*/}
        {/*    }}*/}
        {/*>*/}
        {/*</CouponButton>*/}
      </ButtonWrapper>
    </Container>
  );
};

export default EndingPage;

const Container = styled.div`
  width: 100svw;
  height: 100svh;

  position: fixed;
  display: none;

  flex-direction: column;
  align-items: center;

  background: rgba(0, 0, 0, 0.7);

  z-index: 102;
`;

const BusWrapper = styled.img`
  position: relative;
  top: 80px;
`;

const ScoreWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  top: 30px;
`;

const ScoreText = styled.div`
  width: 110px;
  height: 28px;

  background: url('${ScoreImg}') 50% / cover no-repeat;
`;

const ScoreGroup = styled.div`
  position: relative;
`;

const ScoreNumShadow = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  transform: translateX(-50%);

  font-feature-settings: 'case' on;
  text-shadow: 0px 5px 0px #3f0000;
  -webkit-text-stroke-color: #3e0000;
  -webkit-text-stroke-width: 1px;
  font-family: 'Russo One';
  font-size: 74px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 48px */
`;

const ScoreNum = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  transform: translateX(-50%);

  text-align: center;
  font-feature-settings: 'case' on;
  font-family: 'Russo One';
  font-size: 74px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 48px */

  background: linear-gradient(180deg, #ffc566 15.63%, #f4795a 85.42%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  display: flex;

  flex-direction: column;
  align-items: center;

  width: calc(100% - 124px);
  bottom: 73px;
`;

const PlayButton = styled.img`
  width: 100%;
  height: 62px;

  position: relative;
  display: flex;
`;

// const CouponButton = styled.img`
//     width: 100%;
//     height: 62px;
//
//     position: relative;
//     display: flex;
// `;
