import {XrObject} from "@hypercloud-kr/webxr-node/dist/XrObject";
import {RacingScene} from "../scenes/RacingScene";
import {Scene, Vector3} from "three";
import {loadGLTFModelAsync} from "../../shared/loader";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import EndLineModel from "../../../assets/glb/Finish.glb";

import {endPosition, ROAD} from "../../shared";
import {LoadingManager} from "../manager/LoadingManager";

export class EndLineObject extends XrObject {
    constructor(private scene: RacingScene) {
        super();

        this.position = new Vector3(ROAD.position.x, ROAD.position.y, endPosition);
        const loading = loadGLTFModelAsync(
            EndLineModel,
            'endLine',
            this.position,
            new Vector3(ROAD.rotation.x, ROAD.rotation.y, ROAD.rotation.z),
            new Vector3(1, 1, 1),
            true
        ).then(this.onLoadModel(scene.scene));
        LoadingManager.getInstance().setState(loading);
    }

    protected onLoadModel(scene: Scene): (model: GLTF) => void {
        return (model: GLTF) => {
            super.onLoadModel(scene)(model);
            model.scene.traverse((child: any) => {
                child.castShadow = true;
                child.receiveShadow = true;
            });
        };
    }

    update() {
        super.update();
    }

    render() {
        super.render();
    }
}
