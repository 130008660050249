const IcCamera = () => <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"
                            fill="none">
    <g clipPath="url(#clip0_7939_956)">
        <g filter="url(#filter0_d_7939_956)">
            <rect x="4" y="18" width="92" height="65" rx="14" fill="#4E5968"/>
            <circle cx="50" cy="51" r="21" fill="#D0D6DE"/>
            <circle cx="50.0001" cy="51.0001" r="14.4" fill="#333D4B"/>
            <path opacity="0.3"
                  d="M60.8663 56.2967C61.3188 56.5173 61.5096 57.0649 61.2579 57.5008C60.5448 58.7357 59.6333 59.845 58.56 60.784C58.1812 61.1154 57.607 61.0344 57.3029 60.6333C56.9988 60.2321 57.0803 59.663 57.4554 59.3272C58.3049 58.5666 59.0332 57.6804 59.6146 56.6995C59.8713 56.2665 60.4138 56.0762 60.8663 56.2967Z"
                  fill="#9C9D9D"/>
            <circle opacity="0.7" cx="50.0001" cy="51.0001" r="8.4" fill="#4E5968"/>
            <circle cx="44.0001" cy="46.2001" r="3.6" fill="#9C9D9D"/>
            <rect x="74" y="26" width="14" height="8" rx="4" fill="#343E4C"/>
            <circle cx="84" cy="30" r="2.5" fill="#E15350"/>
            <rect x="13" y="66" width="8" height="3" rx="1.5" fill="#343E4C"/>
            <rect x="13" y="71" width="12" height="3" rx="1.5" fill="#343E4C"/>
        </g>
    </g>
    <defs>
        <filter id="filter0_d_7939_956" x="-2" y="10.8" width="111.2" height="84.2" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset dx="3.6" dy="2.4"/>
            <feGaussianBlur stdDeviation="4.8"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7939_956"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7939_956" result="shape"/>
        </filter>
        <clipPath id="clip0_7939_956">
            <rect width="100" height="100" fill="white"/>
        </clipPath>
    </defs>
</svg>

export default IcCamera;
