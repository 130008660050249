import styled from "styled-components";

const CountDownUI = () => {
    return <Wrapper id={'countDownWrapper'}>
        <CountDownImg id={'countDown'} count={3}/>
    </Wrapper>
}

export default CountDownUI;

const Wrapper = styled.div`
    position: fixed;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    top: 84px;
    
    gap: 2px;
    
    z-index: 2;
`;

const CountDownImg = styled.div<{ count: number }>`
    width: 59px;
    height: 59px;
    
    display: flex;
`;
