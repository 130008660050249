import styled from "styled-components";
import GaugeImg from "../assets/png/img_gauge-bar.png";
import GaugeBus from "../assets/png/img_bus.png";
import ScoreUI from "./ScoreUI";
import StartImg from "../assets/png/Start.png";
import SuccessImg from "../assets/png/Success.png";
import CountDownUI from "./CountDownUI";
import Confetti from "../assets/confetti.gif";

const RacingUI = () => {
    return <UIView id={'uiView'}>
        <GaugeBarWrapper id={'gauge'}>
            <GaugeBarBackground id={'gaugeBarBackground'}>
                <GaugeBarImage id={'gaugeBarImage'}/>
            </GaugeBarBackground>
            <GaugeBarBus id={'gaugeBarBus'}/>
        </GaugeBarWrapper>
        <CountDownUI />
        <Start id={'start'} />
        <ConfettiGif id={'confetti'} src={Confetti} />
        <Success id={'success'} />
        <ScoreUI/>
    </UIView>
}

export default RacingUI;

const UIView = styled.div`
    width: 100svw;
    height: 100svh;
    
    position: fixed;
    display: inline-flex;
    
    flex-direction: column;
    align-items: center;
    
    z-index: 2;
`;

const GaugeBarWrapper = styled.div`
    position: fixed;
    display: flex;

    justify-content: center;
    align-items: center;

    top: 16px;
    
    width: calc(100% - 40px);
    height: 32px;
`;

const GaugeBarBackground = styled.div`
    position: fixed;

    width: calc(100% - 40px);
    height: 24px;
    
    border-radius: 999px;
    border: 2px solid rgba(0, 0, 0, 0.40);
    background: rgba(29, 30, 33, 0.70);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
`;

const GaugeBarImage = styled.div`
    background-image: url("${GaugeImg}");
    background-size: cover;
    
    width: 10%;
    height: 24px;
    
    border-top-left-radius: 78px;
    border-bottom-left-radius: 78px;
`;

const GaugeBarBus = styled.div`
    position: absolute;

    background-image: url("${GaugeBus}");
    background-size: cover;
    
    width: 48px;
    height: 48px;
    
    left: 0;
`;

const Start = styled.div`
    position: fixed;
    display: none;

    width: 234px;
    height: 54px;
    
    top: 78px;

    background: url('${StartImg}') 50% / cover no-repeat;
`;

const ConfettiGif = styled.img`
    position: fixed;
    display: none;
    top: 0;
`;

const Success = styled.div`
    position: fixed;
    display: none;

    width: 328px;
    height: 54px;
    
    top: 78px;

    background: url('${SuccessImg}') 50% / cover no-repeat;
`;

